<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="box clearfix">
        <user-left :name="'setting'"></user-left>
        <div class="user-right" ref="right">
          <div class="title-top"><strong>修改密码</strong></div>
            <div class="form-box">
              <div class="a-of-h">
                <div class="item-name">旧密码</div>
                <div class="item-input">
                  <input v-model="form.oldpassword" placeholder="请输入旧密码" />
                </div>
              </div>
              <div class="a-of-h">
                <div class="item-name">新密码</div>
                <div class="item-input">
                  <input v-model="form.newpassword" placeholder="请输入新密码" />
                </div>
              </div>
              <div class="a-of-h">
                <div class="item-name">确认密码</div>
                <div class="item-input">
                  <input v-model="form.repassword" placeholder="请再次输入密码" />
                </div>
              </div>
              <button type="button" class="btn" @click="submitForm">提交</button>
            </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import FooterNav from '@/components/FooterNav'
import UserLeft from '@/components/UserLeft'

export default {
  name: 'user',
  data () {
    return {
      current: 'user',
      form: {
        oldpassword: '',
        newpassword: '',
        repassword: ''
      }
    }
  },
  methods: {
    submitForm: function () {
      if (!this.form.oldpassword) {
        this.$layer.msg('请输入旧密码！')
        return true
      }
      if (!this.form.newpassword) {
        this.$layer.msg('请输入新密码！')
        return true
      }
      if (this.form.newpassword !== this.form.repassword) {
        this.$layer.msg('两次密码输入不一致，请重新输入！')
        return true
      }
      let params = {
        old_password: this.form.oldpassword,
        new_password: this.form.newpassword
      }
      this.$post('user/changePwd', params).then(res => {
        this.$layer.msg(res.msg)
        if (res.code === 1) {
          localStorage.removeItem('token')
          this.$router.push('/login')
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    }
  },
  components: {
    HeaderNav,
    FooterNav,
    UserLeft
  },
  mounted: function () {
    var h = document.documentElement.clientHeight || document.body.clientHeight
    this.$refs.content.style.minHeight = h - 124 + 'px'
    this.$refs.right.style.minHeight = h - 144 + 'px'
  },
  created () {
    // 获取用户信息
  }
}
</script>

<style lang="less" scoped>
@import url('../assets/css/page/edit');
</style>
